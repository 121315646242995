import React from "react";
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl";
import Layout from "../components/Layout";

const NotFoundPage = ({ intl }) => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h1>
          <FormattedMessage id="notfound.header" />
        </h1>
        <p>
          <FormattedMessage id="notfound.description" />
        </p>
      </header>
    </article>
  </Layout>
);

export default injectIntl(NotFoundPage);
